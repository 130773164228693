import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Icon from "../../../components/custom-widgets/icon";
import OnlineBusinessBanking from "../../../components/business-banking/online-business-banking";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const BusinessSavingsAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/money-market-account/hero-business-money-market-02242023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "money-market-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Young businessman with tablet sitting at desk in office.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Earn a Higher Rate and Keep Funds Accessible"
          }
        },
        {
          id: 2,
          button: {
            id: "money-market-account-hero-cta",
            text: "Open an Account",
            url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyG5",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-savings-account",
      title: "Business Savings"
    },
    {
      id: 3,
      active: true,
      title: "Business Money Market"
    }
  ];

  const title = "Business Money Market Account";
  const description =
    "Grow your business capital by opening an interest bearing business money market account. Only $100 to open. Open an interest business account today!";

  const SEOData = {
    title: "Business Money Market Account",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-savings-account/money-market-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-money-market-02242023.jpg"
      }
    ]
  };

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/is-starting-a-business-worth-it",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    sectionClass: "bg-white pb-2",
    cards: featuredCardsData
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section id="business-mm-intro-section" className="container adjust-padding-bottom">
        <h1>Business Money Market</h1>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
          <div className="col mb-3 mb-lg-4">
            <h3 className="text-success">
              <Link id="business-cd-money-market-rates-link" to="/business-banking/business-cd-money-market-rates">
                Earn more interest
              </Link>{" "}
              for your business' savings with a business money market. Deposit and withdraw funds as needed so you can
              stay flexible with your savings!
            </h3>
            <div className="mb-3">
              <a
                href="https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyG5"
                className="btn btn-primary btn-block"
                id="business-mm-open-account-btn"
                target="_blank"
              >
                Open an Account
              </a>
            </div>
            <Link
              id="cd-money-market-rates-link"
              to="/business-banking/business-cd-money-market-rates"
              className="text-decoration-none"
            >
              See Today's Rates <Icon name="arrow-right" class="ml-1" />
            </Link>
          </div>
          <div className="col text-center mb-3 mb-lg-4">
            <img src="../../../images/icons/icon-100-dollars.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">Only $100 initial deposit to open</h3>
            <h4>
              it's a great way to{" "}
              <Link to="/business-banking/business-cd-money-market-rates" id="cd-money-market-rates-link-2">
                earn interest
              </Link>{" "}
              on your extra cash.
            </h4>
          </div>
          <div className="col text-center mb-3 mb-lg-4">
            <img src="../../../images/icons/icon-1000-interest.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">Minimum balance to earn interest $1000</h3>
          </div>
          <div className="col text-center mb-3 mb-lg-4">
            <img src="../../../images/icons/icon-slash-12.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">We will waive the $12 monthly fee</h3>
            <h4>with balances of $1000 or more.</h4>
          </div>
        </div>
      </section>
      <OnlineBusinessBanking />

      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default BusinessSavingsAccount;
